@import (less) "./colors.less";

.status
{
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: -2px;
    /* Gray / gray-1 */
    border: 1px solid @white;
    border-radius: 50%;
    height: 8px;
    width: 8px;

    &-on
    {
        &:extend(.status);

        /* Polar Green / green-6 */
        background: @green;

        &-label
        {
            color: @green;
        }
    }

    &-off
    {
        &:extend(.status);
        
        /* Dust Red / red-6 */
        background: @red;

        &-label
        {
            color: @red;
        }
    }

    &-primary
    {
        &:extend(.status);
        
        background: @blue;

        &-label
        {
            color: @blue;
        }
    }
}