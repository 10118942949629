@import (less) "./colors.less";
@import (less) "./transition.less";

body
{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
        Microsoft YaHei, Arial, sans-serif;
}

html
{
    box-sizing: border-box;
}

*,
*:before,
*:after
{
    box-sizing: inherit;
}

.no-padding
{
    padding: 0px !important;
}

.padding-content
{
    padding: 4px 0;
}

a:focus,
a:active
{
    outline: none;
}

a,
a:focus,
a:hover
{
    cursor: pointer;
    text-decoration: none;
    color: @blue;
}

div:focus
{
    outline: none;
}

.fr
{
    float: right;
}

.fl
{
    float: left;
}

.pr-5
{
    padding-right: 5px;
}

.pl-5
{
    padding-left: 5px;
}

.pl-10
{
    padding-left: 5px;
}

.block
{
    display: block;
}

.pointer
{
    cursor: pointer;
}

.inlineBlock
{
    display: block;
}

.clearfix
{
    &:after
    {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
}

.app-container
{
    padding: 0 0 20px 20px;
    min-width: 1150px;
    overflow-x: hidden;
}

.ant-layout
{
    background: @white !important;
    overflow-x: auto;
    max-width: 100vw;
}

.ant-layout-content
{
    flex: none !important;
    max-width: calc(100vw - 310px);
}
