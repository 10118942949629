@import (less) "@/styles/colors.less";

.sidebar-menu-container
{
    height: 100%;
    min-height: calc(100vh - 64px * 2);
    border-right: 1px solid @border-gray;

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left
    {
        border-right: none;
    }
}

.ant-layout-sider
{
    max-width: 282px !important;
    width: 282px !important;
    flex: 0 0 282px !important;
}

.ant-menu-sub
{
    background-color: @white !important;
}