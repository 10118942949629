@import (less) "@/styles/text.less";

.horizontal-navigation
{
    border-bottom: none;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 10px !important;
    margin-bottom: 20px !important;

    .ant-menu-item
    {
        margin: 0;
        padding: 0 !important;
        margin-right: 20px !important;
        line-height: normal;

        &:hover:enabled
        {
            cursor: pointer !important;
        }

        &:after {
            right: 0px !important;
            bottom: -10px !important;
            left: 0px !important;
        }
    }
}

.actions
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px 20px 20px;
    min-width: 700px;
    overflow-x: hidden;

    h2
    {
        margin: 0;
    }
}
