@import (less) "@/styles/colors.less";

.red-button
{
    background-color: @red !important;
    border-color: @red !important;
    margin-right: 6px;
}

.green-button
{
    background-color: @green !important;
    border-color: @green !important;
}

.drawer {
    &-title {
        margin: 0!important;
        font-size: 21px;
    }
}