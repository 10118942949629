@import (less) "./colors.less";

.centred
{
    text-align: center;
}

.bold
{
    font-weight: bold;
}

.placeholder
{
    color: @placeholder-gray !important;
}

.operation-details-header
{
    &:extend(.bold);
    margin: 0 !important;
}

.multiline-as-is
{
    white-space: pre
}