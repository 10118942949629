@import (less) "./colors.less";

.clickable
{
    color: @blue;

    &:hover
    {
        cursor: pointer;
    }
}

.hovered-link
{
    &:hover
    {
        span:not(.anticon) {
            border-bottom: 1px solid @blue;
        }
    }
}
