.full-width
{
    width: 100%;
}

.d-flex {
    display: flex !important;
}

.flex-1 {
    flex: 1;
}

.items-center {
    align-items: center;
}

.multiple-element-container
{
    white-space: nowrap;
    padding-bottom: 3px;
}

.multiple-element
{
    width: 90% !important;
}

.divider
{
    margin-top: 0px !important;
    margin-bottom: 5px !important;
}

.ant-table-column-sorter {
	margin-left: 8px;
}

.ant-card {
    background-color: #fafafa !important;
}
