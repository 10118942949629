@import (less) "./colors.less";

.row
{
    min-width: 280px;
    overflow-x: hidden;

    &-simple
    {
        margin-bottom: 20px;
        overflow-wrap: break-word;
    }
}

.site-card-wrapper {
    background: #ececec;
    padding: 20px;
}
