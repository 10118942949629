@import (less) "./colors.less";

:not(.ant-form-inline) {
    > .ant-form-item {
        margin-bottom: 24px !important;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}

.ant-form-item-label {
    display: flex;

    > label {
        min-height: 100%;
        height: auto !important;
        align-items: baseline !important;
        margin-top: 5px !important;
    }
}

.form-label {
    white-space: pre-line;
}
