@import (less) "@/styles/colors.less";
@import (less) "@/styles/components.less";
@import (less) "@/styles/text.less";

.auth-container
{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @auth-background;

    .title
    {
        margin: 0px auto 30px auto;
        text-align: center;
        font-weight: bold;
    }

    .content
    {
        background-color: @white;
        width: 320px;
        padding: 30px;
        box-shadow: 0 0 10px 2px @shadow;
        border-radius: 3px;
    }

    .small-indent
    {
        margin-bottom: 5px !important;
    }
}