.full-width {
  width: 100%;
}
.d-flex {
  display: flex !important;
}
.flex-1 {
  flex: 1;
}
.items-center {
  align-items: center;
}
.multiple-element-container {
  white-space: nowrap;
  padding-bottom: 3px;
}
.multiple-element {
  width: 90% !important;
}
.divider {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}
.ant-table-column-sorter {
  margin-left: 8px;
}
.ant-card {
  background-color: #fafafa !important;
}
.row {
  min-width: 280px;
  overflow-x: hidden;
}
.row-simple {
  margin-bottom: 20px;
  overflow-wrap: break-word;
}
.site-card-wrapper {
  background: #ececec;
  padding: 20px;
}
.prefix {
  margin-right: 5px !important;
}
.postfix {
  margin-left: 5px !important;
}
.centred {
  text-align: center;
}
.bold,
.operation-details-header {
  font-weight: bold;
}
.placeholder {
  color: #BFBFBF !important;
}
.operation-details-header {
  margin: 0 !important;
}
.multiline-as-is {
  white-space: pre;
}
