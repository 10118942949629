@import (less) "@/styles/text.less";
@import (less) "./colors.less";

.date
{
    color: @gray;
}

.time
{
    &:extend(.placeholder);
}