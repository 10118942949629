@import (less) "@/styles/colors.less";

.ant-layout-footer
{
    border-top: 1px solid @border-gray;
    background: @white !important;

    .footer-buttons > *
    {
        margin: 0 3px;
    }
}