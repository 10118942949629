.centred {
  text-align: center;
}
.bold,
.operation-details-header {
  font-weight: bold;
}
.placeholder {
  color: #BFBFBF !important;
}
.operation-details-header {
  margin: 0 !important;
}
.multiline-as-is {
  white-space: pre;
}
.status,
.status-on,
.status-off,
.status-primary {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: -2px;
  /* Gray / gray-1 */
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.status-on {
  /* Polar Green / green-6 */
  background: #52C41A;
}
.status-on-label {
  color: #52C41A;
}
.status-off {
  /* Dust Red / red-6 */
  background: #F5222D;
}
.status-off-label {
  color: #F5222D;
}
.status-primary {
  background: #1890FF;
}
.status-primary-label {
  color: #1890FF;
}
.row {
  min-width: 280px;
  overflow-x: hidden;
}
.row-simple {
  margin-bottom: 20px;
  overflow-wrap: break-word;
}
.site-card-wrapper {
  background: #ececec;
  padding: 20px;
}
.full-width {
  width: 100%;
}
.d-flex {
  display: flex !important;
}
.flex-1 {
  flex: 1;
}
.items-center {
  align-items: center;
}
.multiple-element-container {
  white-space: nowrap;
  padding-bottom: 3px;
}
.multiple-element {
  width: 90% !important;
}
.divider {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}
.ant-table-column-sorter {
  margin-left: 8px;
}
.ant-card {
  background-color: #fafafa !important;
}
