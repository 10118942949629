@import (less) "../index.less";

.title-without-margin
{
    .auth-container .title;

    margin: 0px auto 0px auto;
}

.recovery-menu
{
    font-size: 14px !important;
    height: 30px;
    margin-bottom: 30px !important;
    border-bottom: none !important;

    // расположение элементов меню по центру
    position: relative;
    display: flex;
    justify-content:center;
}