@import (less) "@/styles/index.less";
@import (less) "@/styles/text.less";
@import (less) "@/styles/grid.less";

.confirmButton
{
    margin: 5px auto 0px;
    display: block !important;
}

.grouped-input
{
    width: calc(100% - 35px) !important;
}
