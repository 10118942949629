@import (less) "@/styles/colors.less";

.ant-layout-header
{
    transition: width 0.2s;
    z-index: 9;
    position: relative;
    background: @white !important;
    padding: 0 24px !important;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid @border-gray;
    
    .header-name
    {
        color: @blue;
        font-size: 20px;
        min-width: 220px;
    }

    .right-menu
    {
        display: flex;
        align-items: center;

        & > *
        {
            margin: 0 3px;
        }

        .header-username-wrapper
        {
            display: flex;
            flex-direction: column;
            padding: 0 4px;
            min-width: 180px;

            span
            {
                line-height: 1;
                padding: 2px;
                font-size: 12px;
            }

            .header-username
            {
                font-weight: 600;
            }
        }
        
        .dropdown-wrap
        {
            padding-left: 20px;

            .ant-dropdown-trigger
            {
                cursor: pointer;
                display: flex;
                align-items: center;
            }
        }
    }
}

.fix-header
{
    position: fixed;
    top: 0;
    right: 0;
}

.user-menu {
        box-shadow: 0 3px 15px 2px @menu-shadow !important;
}